import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header className="header">
          <div className="inner">
            <div className="row header-top">
              <div className="four offset-by-six columns">
                <nav>
                  <ol className="uilist-hor nav-top">
                    <li className="first">
                      <a href="/fr/contact/">Contact</a>
                    </li>
                    <li>
                      <a href="/fr/lexique/">Lexique</a>
                    </li>
                    <li className="active last">
                      <a href="/fr/faq-questions-diarrhee/">FAQ</a>
                    </li>
                  </ol>
                  <ul className="uilist-hor nav-top nav-lang">
                    <li id="uilist-hor nav-top nav-lang_nl-BE">
                      <a href="/veelgestelde-vragen-diarree/">NL</a>
                    </li>
                    <li id="uilist-hor nav-top nav-lang_fr" className="active">
                      <a href="/fr/faq-questions-diarrhee/">FR</a>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="two columns">
                <form
                  className="search"
                  method="post"
                  action="/"
                >
                  <div className="hiddenFields">
                    <input
                      type="hidden"
                      name="XID"
                      defaultValue="59e85480201901d616849f994dd72a72ef925a2a"
                    />
                    <input type="hidden" name="ACT" defaultValue="45" />
                    <input
                      type="hidden"
                      name="result_page"
                      defaultValue="/fr/chercher"
                    />
                    <input
                      type="hidden"
                      name="collection"
                      defaultValue="pages"
                    />
                    <input
                      type="hidden"
                      name="search_mode"
                      defaultValue="all"
                    />
                    <input type="hidden" name="site_id" defaultValue="1" />
                  </div>
                  <div className="row">
                    <div className="twelve columns">
                      
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="row header-bottom">
              <div className="two columns">
                <span className="logo">
                  <a href="/fr/">Imodium®</a>
                </span>
              </div>
              <div className="ten columns">
                <nav className="top-bar">
                  <ul className="nav-info">
                    <li className="name">
                      <h1>
                        <a href="#">Menu</a>
                      </h1>
                    </li>
                    <li className="toggle-topbar">
                      <a href="#" />
                    </li>
                  </ul>
                  <section>
                    <ol className="uilist-hor nav-main">
                      <li className="first" id="nav-sub-4">
                        <a href="/fr/comment-agit-imodium/">
                          Comment agit
                          <br />
                          IMODIUM®?
                        </a>
                      </li>
                      <li id="nav-sub-5">
                        <a href="/fr/medicaments-imodium/">
                          Médicaments <br />
                          IMODIUM®
                        </a>
                      </li>
                      <li id="nav-sub-6">
                        <a href="/fr/la-diarrhee/">
                          Diarrhée – causes
                          <br />
                          et traitement
                        </a>
                      </li>
                      <li id="nav-sub-8">
                        <a href="/fr/diarrhee-en-voyage/">
                          Diarrhée
                          <br />
                          en voyage
                        </a>
                      </li>
                      <li id="nav-sub-9">
                        <a href="/fr/diarrhee-chez-les-enfants/">
                          Diarrhée chez
                          <br />
                          les enfants
                        </a>
                      </li>                      
                    </ol>
                  </section>
                </nav>
                <script
                  type="text/javascript"
                  dangerouslySetInnerHTML={{
                    __html:
                      '\nvar item = document.getElementById("nav-sub-10").getElementsByTagName("a");\nvar span = document.createElement("span");\nspan.className = "menu-info";\nspan.innerHTML = " ";\nitem[0].appendChild(span);\n'
                  }}
                />
              </div>
            </div>
          </div>
        </header>
        <div className="main row">
          <div className="sidebar three columns">
            <ul className="side-nav">
              <li className="active first overview last">
                <a href="/fr/faq-questions-diarrhee/"> FAQ</a>
              </li>
            </ul>
            <div className="widget">
              <div className="inner">
                <h4>Conseils en cas de diarrhée</h4>
                <div className="img">
                  <img
                    src="/assets/files/widgets/images/teaser_durchfallmythen.jpeg"
                    width="170"
                    height="102"
                    alt="Conseils en cas de diarrhée"
                  />
                </div>
                <p>
                  Quand il s'agit du traitement des symptômes de la diarrhée, il
                  existe encore de nombreuses incertitudes. Lire quelques
                  conseils.
                </p>
                <a href="/fr/la-diarrhee/conseils-pour-le-traitement-des-symptomes-de-la-diarrhee/">
                  Conseils diarrhée
                </a>
              </div>
            </div>
            <div className="widget">
              <div className="inner">
                <h4>Pharmacie de voyage</h4>
                <div className="img">
                  <img
                    src="/assets/files/widgets/images/teaser_reisen.jpeg"
                    width="170"
                    height="102"
                    alt="Pharmacie de voyage"
                  />
                </div>
                <p>
                  Ce que doit contenir la&nbsp;
                  <strong>pharmacie de voyage</strong>? Trouvez ici toutes les
                  informations pratiques.
                </p>
                <a href="/fr/diarrhee-en-voyage/pharmacie-de-voyage/">
                  Plus d'info
                </a>
              </div>
            </div>
          </div>
          <div className="page nine columns page-45">
            <h1>Foire aux Questions - la diarrhée</h1>
            <div className="intro row">
              <div className="twelve columns">
                <p>
                  <a
                    data-close="Tout fermer"
                    data-open="Tout visualiser"
                    href="#"
                    id="toggleAllQuestions"
                  >
                    Tout visualiser
                  </a>
                </p>
              </div>
            </div>
            <div className="question">
              <h4>
                <a href="/fr/veelgestelde-vragen-diarree/des-raisons-possibles-a-une-diarrhee-recurrente/">
                  Que dois-je faire si je ne suis pas sûr de ce qui provoque ma
                  diarrhée récurrente?
                </a>
              </h4>
              <div className="answer">
                <p />
                <p>
                  Si vous avez des symptômes de diarrhée qui sont persistants,
                  récurrents ou accompagnés de sang, ou d'autres symptômes, vous
                  devez consulter votre médecin - particulièrement si vous avez
                  récemment séjourné à l'étranger dans un pays en voie de
                  développement. Toutefois, une intolérance alimentaire ou le
                  stress peuvent également être des raisons possibles à une
                  diarrhée récurrente. IMODIUM® peut apporter une aide dans la
                  plupart de ces cas, mais il est important d'avoir d'abord
                  l'avis de votre pharmacien ou de votre médecin.
                </p>
                <p />
              </div>
            </div>
            <div className="question">
              <h4>
                <a href="/fr/veelgestelde-vragen-diarree/quel-traitement-puis-je-prendre-si-je-narrive-pas-a-avaler-de-comprimes/">
                  Quel traitement puis-je prendre si je n’arrive pas à avaler de
                  comprimés?
                </a>
              </h4>
              <div className="answer">
                <p />
                <p>
                  Ne vous inquiétez pas, vous pouvez obtenir un soulagement
                  rapide lorsque la diarrhée survient. Les comprimés fondants
                  instantanés IMODIUM® Instant sont conçus pour se dissoudre en
                  quelques secondes sur votre langue, sans nécessiter d'eau.
                </p>
                <p />
              </div>
            </div>
            <div className="question">
              <h4>
                <a href="/fr/faq-questions-diarrhee/pourquoi-la-diarrhee-quand-je-mange-et-bois-en-grande-quantite/">
                  Pourquoi ai-je toujours tendance à avoir la diarrhée quand je
                  mange et bois en grande quantité?
                </a>
              </h4>
              <div className="answer">
                <p />
                <p>
                  Un excès de nourriture et de boisson tend à faire fonctionner
                  trop rapidement notre système digestif, occasionnant la
                  diarrhée. Dès lors, essayez toujours de manger raisonnablement
                  et d'éviter les excès! Cependant, si la diarrhée survient, une
                  dose d´IMODIUM® Instant comprimés orodispersibles ou IMODIUM® Capsules peut rapidement en soulager les symptômes et
                  vous aider à rétablir la situation.
                </p>
                <p />
              </div>
            </div>
            <div className="question">
              <h4>
                <a href="/fr/veelgestelde-vragen-diarree/que-faire-si-mes-enfants-ont-la-diarrhee/">
                  Que faire si mes enfants ont la diarrhée?
                </a>
              </h4>
              <div className="answer">
                <p />
                <p>
                  Les enfants sont moins bien armés que les adultes pour faire
                  face à la perte de liquide corporel lors d'une diarrhée ; et
                  donc, la mesure principale consiste à renouveler rapidement
                  ces liquides. Votre pharmacien peut vous procurer des
                  solutions de réhydratation orale dans ce but. Si le problème
                  subsiste ou si l'enfant a moins de 6 ans, il est conseillé de
                  voir un médecin.
                </p>
                <p>
                  Les enfants de plus de 6 ans peuvent être soignés avec IMODIUM®.
                </p>
                <p>
                  Pour en savoir plus, consultez notre section&nbsp;{" "}
                  <a href="/fr/diarrhee-chez-les-enfants/">
                    Diarrhée chez les enfants
                  </a>
                  .
                </p>
                <p />
              </div>
            </div>
            <div className="question">
              <h4>
                <a href="/fr/faq-questions-diarrhee/gene-de-demander-un-traitement-antidiarrheique-a-la-pharmacie/">
                  Je suis gêné de demander un traitement antidiarrhéique à la
                  pharmacie. Que puis-je faire?
                </a>
              </h4>
              <div className="answer">
                <p />
                <p>
                  La diarrhée peut vous mettre mal à l'aise de plus d'une façon
                  - particulièrement si vous êtes chez le pharmacien avec une
                  file de plusieurs personnes derrière vous. Souvenez-vous
                  simplement que la diarrhée est l'une des plaintes les plus
                  fréquentes auxquelles est confronté votre pharmacien. Ils sont
                  formés à comprendre votre problème et à dispenser un avis
                  professionnel, et peuvent vous proposer le traitement IMODIUM®
                  adéquat. Si vous vous sentez particulièrement embarrassé,
                  pourquoi ne pas demander à parler au pharmacien en privé?
                </p>
                <p />
              </div>
            </div>
            <div className="question">
              <h4>
                <a href="/fr/faq-questions-diarrhee/pourquoi-ai-je-souvent-la-diarrhee-pendant-mes-regles/">
                  Pourquoi ai-je souvent la diarrhée pendant mes règles?
                </a>
              </h4>
              <div className="answer">
                <p />
                <p>
                  Malheureusement, de nombreuses femmes souffrent de diarrhée
                  pendant la menstruation. On pense qu'elle est causée par les
                  modifications de l'équilibre hormonal. Mais vous ne devez pas
                  laisser gâcher cette période du mois&nbsp;; vous pouvez
                  traiter les symptômes de la&nbsp; diarrhée de manière efficace
				  avec IMODIUM®.
                </p>
                <p>
                  Pour en savoir plus, consultez notre section{" "}
                  <a href="/fr/la-diarrhee/la-diarrhee-pendant-les-regles/">
                    Diarrhée pendant vos règles
                  </a>
                  .
                </p>
                <p />
              </div>
            </div>
            <div className="question">
              <h4>
                <a href="/fr/veelgestelde-vragen-diarree/est-ce-que-votre-estomac-est-sensible-a-la-nourriture-epicee/">
                  Il arrive que mon estomac soit sensible à la nourriture
                  épicée. Que puis-je y faire?
                </a>
              </h4>
              <div className="answer">
                <p />
                <p>
                  La nourriture épicée est une cause fréquente de diarrhée -
                  certains systèmes digestifs ne sont tout simplement pas faits
                  pour gérer ce "feu". Si vous appréciez malgré tout les
                  aliments épicés et que vous ne voulez pas vous en passer,
                  veillez à avoir un traitement antidiarrhéique à portée de
                  main! Une dose d´IMODIUM® Instant comprimés orodispersibles 
				  ou IMODIUM® Capsules peut soulager rapidement vos symptômes.
                </p>
                <p />
              </div>
            </div>
            <div className="question">
              <h4>
                <a href="/fr/faq-questions-diarrhee/le-diarrhee-avec-des-crampes-et-des-ballonnements/">
                  Lorsque je souffre de diarrhée, j’ai aussi des crampes, je me
                  sens ballonné et j’ai des gaz. Que puis-je prendre pour
                  traiter tous ces symptômes?
                </a>
              </h4>
              <div className="answer">
                <p />
                <p>
                  Beaucoup de personnes constatent que la diarrhée survient avec
                  d'autres symptômes désagréables, tels que des crampes
                  d'estomac, des gaz et des ballonnements. Ils sont occasionnés
                  par un excès de gaz dans les intestins. IMODIUM® Duo comprimés
                  a un ingrédient supplémentaire pour s'attaquer à cela, ainsi
                  que pour traiter les symptômes de la diarrhée. IMODIUM® Duo
                  est disponible en comprimés faciles à avaler.
                </p>
                <p />
              </div>
            </div>
            <div className="question">
              <h4>
                <a href="/fr/faq-questions-diarrhee/pourquoi-est-ce-qon-attrape-des-maux-de-ventre-quand-on-est-nerveux/">
                  Quand je dois parler en public, je deviens si nerveux que j’en
                  attrape des maux de ventre. Pourquoi donc?
                </a>
              </h4>
              <div className="answer">
                <p />
                <p>
                  La diarrhée occasionnée par le stress de produire en public
                  est un problème reconnu en tant que tel, il a même son propre
                  nom - la diarrhée de trac, ou PAD (Performance Anxiety
                  Diarrhoea).&nbsp;Si vous savez que vous en souffrez, vous
                  pouvez emporter une boîte d’IMODIUM® Instant comprimés
                  orodispersibles.
                </p>
                <p>
                  Pour en savoir plus, consultez notre section{" "}
                  <a href="/fr/la-diarrhee/stress-et-diarrhee/">
                    Stress et diarrhée
                  </a>
                  .
                </p>
                <p />
              </div>
            </div>
            <div className="question">
              <h4>
                <a href="/fr/faq-questions-diarrhee/imodium-peut-il-provoquer-une-constipation/">
                  IMODIUM® peut-il provoquer une constipation?
                </a>
              </h4>
              <div className="answer">
                <p />
                <p>
                  IMODIUM® provoque rarement une constipation. Tant que vous
                  respectez la dose prescrite, IMODIUM® peut vous soulager
                  de manière efficace de la diarrhée, et souvent déjà
                  avec une seule dose. Mais n’oubliez pas qu’en cas de diarrhée,
                  l’intestin est souvent complètement vidé. Il faut parfois
                  attendre un à deux jours jusqu’à ce qu’il se remplisse à
                  nouveau.
                </p>
                <p />
              </div>
            </div>
            <div className="question">
              <h4>
                <a href="/fr/veelgestelde-vragen-diarree/puis-je-utiliser-imodium-si-je-suis-enceinte-ou-si-jallaite/">
                  Puis-je utiliser IMODIUM® si je suis enceinte ou si j’allaite?
                </a>
              </h4>
              <div className="answer">
                <p />
                <p>
                  Il n'est pas conseillé de prendre IMODIUM® pendant la
                  grossesse ou l'allaitement. Vous devez demander conseil à
                  votre médecin ou votre sage-femme pour un traitement de
                  remplacement.
                </p>
                <p />
              </div>
            </div>
            <div className="question">
              <h4>
                <a href="/fr/veelgestelde-vragen-diarree/les-solutions-de-rehydratation-orale-traitent-elles-la-diarrhee/">
                  Les solutions de réhydratation orale traitent-elles la
                  diarrhée?
                </a>
              </h4>
              <div className="answer">
                <p />
                <p>
                  Les solutions de réhydratation orale ne sont destinées qu'au
                  renouvellement des liquides et électrolytes (les "sels")
                  perdus par l'organisme, afin d'éviter la déshydratation. Ils
                  ne combattent pas les symptômes de la diarrhée.
                </p>
                <p>
                  Pour en savoir plus, consultez notre section{" "}
                  <a href="/fr/la-diarrhee/causes-de-la-diarrhee/">
                    Causes de la diarrhée
                  </a>
                  .
                </p>
                <p />
              </div>
            </div>
            <div className="question">
              <h4>
                <a href="/fr/veelgestelde-vragen-diarree/les-enfants-peuvent-ils-utiliser-imodium/">
                  Les enfants peuvent-ils utiliser IMODIUM®?
                </a>
              </h4>
              <div className="answer">
                <p />
                <p>
                  Seuls les enfants à partir de 6 ans et les adolescents&nbsp;
                  peuvent prendre IMODIUM®. Pour les enfants de moins de 6 ans,
                  on peut utiliser des solutions de réhydratation orale, afin
                  d’éviter la déshydratation en cas de diarrhée. Pour en savoir
                  plus, consultez notre section Diarrhée chez les enfants.
                </p>
                <p />
              </div>
            </div>
            <div className="question">
              <h4>
                <a href="/fr/veelgestelde-vragen-diarree/nest-il-pas-preferable-de-laisser-la-diarrhee-suivre-son-cours-pour-se-deba/">
                  N’est-il pas préférable de laisser la diarrhée suivre son
                  cours pour se débarrasser des microbes?
                </a>
              </h4>
              <div className="answer">
                <p />
                <p>
                  Certaines personnes croient que la diarrhée est la manière de
                  l'organisme "d'évacuer" les microbes qu'ils pensent être la
                  cause de la diarrhée, et que les médicaments antidiarrhéiques
                  agissent comme un bouchon, retenant les microbes à
                  l'intérieur.
                </p>
                <p>
                  <strong>Ce n'est cependant pas le cas, en réalité.</strong>
                </p>
                <p>
                  Bien que la diarrhée puisse être causée par des microbes, ou
                  plutôt des bactéries, elle est aussi très souvent simplement
                  provoquée par le stress ou l'anxiété, ou juste un changement
                  de climat. Si des bactéries sont présentes, l'organisme s'en
                  débarrassera généralement en une journée environ. Mais
                  entre-temps, IMODIUM® peut vous aider à vous sentir mieux et à
                  poursuivre vos activités habituelles. En utilisant IMODIUM®,
                  vous pouvez contribuer à éviter les selles liquides qui
                  entraînent la perte de liquides et nutriments vitaux. Mais si
                  les symptômes persistent, vous devez demander conseil à votre
                  médecin.
                </p>
                <p />
              </div>
            </div>
            <div className="question">
              <h4>
                <a href="/fr/veelgestelde-vragen-diarree/ai-je-besoin-dune-prescription-pour-imodium/">
                  Ai-je besoin d’une prescription pour IMODIUM®?
                </a>
              </h4>
              <div className="answer">
                <p />
                <p>
                  Non. Tous les produits d’IMODIUM® sont disponibles en
                  pharmacie sans ordonnance.
                </p>
                <p />
              </div>
            </div>
            <div className="question">
              <h4>
                <a href="/fr/veelgestelde-vragen-diarree/quest-ce-que-la-diarrhee/">
                  Qu’est-ce que la diarrhée?
                </a>
              </h4>
              <div className="answer">
                <p />
                <p>
                  Lorsque votre système digestif fonctionne normalement, la
                  nourriture et les liquides transitent de l'estomac dans
                  l'intestin grêle, puis dans le côlon. L'intestin grêle et le
                  côlon sont tapissés de cellules qui absorbent les nutriments
                  et l'eau dont l'organisme a besoin. Le reste de la nourriture
                  et de l'eau transitent alors dans l'organisme, sous la forme
                  de selles.
                </p>
                <p>
                  Lorsque ces cellules sont irritées, le mouvement
                  habituellement lent et rythmé des intestins peut devenir
                  hyperactif, transportant nourriture et liquides trop
                  rapidement à travers le côlon, de sorte que de moindres
                  quantités en sont absorbées par l'organisme. Ceci provoque des
                  selles liquides - mieux connues sous le nom de diarrhée.
                </p>
                <p>
                  Pour en savoir plus, consultez notre section{" "}
                  <a href="/fr/la-diarrhee/causes-de-la-diarrhee/">
                    Causes de la diarrhée
                  </a>
                  .
                </p>
                <p />
              </div>
            </div>
            <div className="question">
              <h4>
                <a href="/fr/faq-questions-diarrhee/quelle-est-la-cause-de-la-diarrhee-aigue-de-courte-duree/">
                  Quelle est la cause de la diarrhée aiguë (c.-à-d. de courte
                  durée)?
                </a>
              </h4>
              <div className="answer">
                <p />
                <p>
                  La diarrhée aiguë peut avoir beaucoup de causes différentes.
                  En voici quelques-unes parmi les principales :
                </p>
                <ul>
                  <li>Infection virale ou bactérienne</li>
                  <li>Allergie ou intolérance à certains aliments</li>
                  <li>Manger de la nourriture trop épicée ou trop grasse</li>
                  <li>Boire une quantité excessive d'alcool</li>
                  <li>Le stress</li>
                  <li>Menstruation</li>
                </ul>
                <p>
                  Pour plus d'informations sur la diarrhée aiguë et comment la
                  traiter, consultez notre section{" "}
                  <a href="/fr/la-diarrhee/">La vérité sur la diarrhée</a>.
                </p>
                <p />
              </div>
            </div>
            <div className="question">
              <h4>
                <a href="/fr/faq-questions-diarrhee/la-diarrhee-que-faire/">
                  La diarrhée – que faire?
                </a>
              </h4>
              <div className="answer">
                <p />
                <p>
                  Vous pouvez traiter vous-même des crises brèves de diarrhée,
                  sauf si vous constatez d'autres symptômes comme de la fièvre,
                  du sang ou du mucus dans vos selles, auxquels cas vous devez
                  consulter un médecin. Les symptômes de la diarrhée s'apaisent
                  avec{" "}
                  <a href="/fr/medicaments-imodium/imodium-capsules/">
                    IMODIUM® Capsules
                  </a>
                  ,{" "}
                  <a href="/fr/medicaments-imodium/imodium-duo/">
                    IMODIUM® Duo comprimés
                  </a>
                  &nbsp;ou{" "}
                  <a href="/fr/medicaments-imodium/imodium-instant/">
                    IMODIUM® Instant comprimés orodispersibles
                  </a>
                  , qui peuvent souvent vous soulager en une seule dose.
                </p>
                <p>Médicaments pour chaque besoin :</p>
                <p>Disponible en pharmacie sans prescription</p>
                <table>
                  <thead>
                    <tr>
                      <th>Médicament</th>
                      <th>Conditionnements disponibles</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>IMODIUM® Instant&nbsp;comprimés orodispersibles</td>
                      <td>20, 60</td>
                    </tr>
                    <tr>
                      <td>IMODIUM® Duo comprimés</td>
                      <td>18</td>
                    </tr>
                    <tr>
                      <td>IMODIUM® Capsules</td>
                      <td>20, 60, 200</td>
                    </tr>
                  </tbody>
                </table>
                
                <p />
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
        <div id="myModal" className="reveal-modal large">
          <style
            dangerouslySetInnerHTML={{
              __html:
                ".embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container video, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }"
            }}
          />
          <div className="embed-container">
            <video
              src="/assets/video/imodium-2018-fr.mp4"
              poster="posterimage.jpg"
            />
          </div>
          <a className="close-reveal-modal">×</a>
        </div>
        <input type="hidden" name="language" defaultValue="fr" id="lg" />
        <script src="//ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js" />
        <script src="https://code.jquery.com/jquery-migrate-3.2.0.js"></script>
        <script
          dangerouslySetInnerHTML={{
            __html:
              "window.jQuery || document.write('<script src=\"/assets/js/vendor/jquery-3.4.1.min.js\"><\\/script>')"
          }}
        />
        <script src="/assets/js/vendor/jquery.flexslider-min.js" />
        <script src="/assets/js/vendor/hash.js" />
        <script src="/assets/js/foundation/jquery.foundation.navigation.js" />
        <script src="/assets/js/foundation/jquery.foundation.topbar.js" />
        <script src="/assets/js/foundation/jquery.foundation.forms.js" />
        <script src="/assets/js/foundation/jquery.foundation.reveal.js" />
        <script src="/assets/js/vendor/swfobject.js" />
        <script src="/assets/js/vendor/yepnope.1.5.4-min.js" />
        <script src="/assets/js/app.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\nfunction cookiesDirectiveScriptWrapper(){\n// declare teh used cookie-3rd-parties\n}\n"
          }}
        />
        
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// The position of the disclosure ('top' or 'bottom')\n// Number of times to display disclosure. Enter 0 to show it forever!!!!\n// The URI of your privacy policy\nif($(\"body\").hasClass(\"nl-BE\")){cookiesDirective('bottom',5,'/cookiebeleid');}else{cookiesDirective('bottom',5,'/fr/politique-en-matiere-de-cookies');}\n"
          }}
        />
      </div>
    );
  }
}

export default Page;
